
<template>
    <div class="time-line" id="timeline">
        <div class="bottom_operate_area" :style="{ width: second * (40+(secWidth*10)) + 'px' }">
            <div class="operate-ruler" :style="{ width: second * (40+(secWidth*10)) + 'px' }">
                <div class="ruler-scale" v-for="(val,index) in second" :key="val" :style="{width: 40+(secWidth*10)+'px'}">
                    <i v-for="item in val==second?11:10" :key="item"></i>
                    <span class="item-scale-time">{{ index }}s</span>
                </div>
                <span class="last-item">{{ second }}s</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'timeline',
    props: {
        second: { //秒
            type: Number,
            default: 1,
        },
        secWidth: {//缩放数
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
        };
    },
    created() {

    },
    watch: {

    },
    methods: {
    },
};
</script>

<style scoped lang="scss">
.time-line {
    position: relative;
    padding: 3px 16px 0;
    .bottom_operate_area {
        height: 100%;
    }
    .operate-ruler {
        width: 100%;
        height: 24px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: nowrap;
        justify-content: left;
        overflow: hidden;
        position: relative;
        .last-item {
            position: absolute;
            top: 0;
            right: -6px;
            color: #ffffff;
            font-size: 8px;
            line-height: 12px;
        }
        .ruler-scale {
            height: 24px;
            position: relative;
            width: 50px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            padding-top: 15px;
            i:nth-child(5n + 1) {
                height: 8px;
                background: #ffffff;
            }
            i {
                display: inline-block;
                width: 1px;
                background: #898989;
                height: 4px;
            }
            :first-child {
                margin-left: 0;
            }
            .item-scale-time {
                font-size: 8px;
                position: absolute;
                line-height: 12px;
                top: 0;
                left: -6px;
                color: #ffffff;
            }
        }
        .ruler-scale:first-child {
            .item-scale-time {
                left: 0px;
            }
        }
    }
}
</style>
