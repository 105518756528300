<template>
    <div class="audioTrack-box" :style="{ width: audioDuration * (40+(secWidth*10)) + 'px' }" @mousedown.stop>
        <div class="audioTrack-one">
            <div class="audioTrackColor"></div>
            <div class="audioTrackImage"></div>
        </div>
        <!-- <div class="audioTrack-list" :class="{active:index==current}" v-for="(item,index) in partList" :key="index" :style="{ width: (item.end - item.start) * (40+(secWidth*10)) + 'px' }" @mousedown.stop="selectActive(index)">
            <div class="audioTrackColor"></div>
            <div class="audioTrackImage"></div>
        </div> -->
    </div>
</template>

<script>
export default {
    props: {
        secWidth: {//缩放数
            type: Number,
            default: 1,
        },
        audioDuration: {
            type: Number,
            default: 1,
        },
    },
    data() {
        return {
            partList: [],
            current: null,
        };
    },
    computed: {

    },
    created() {

    },
    mounted() {
        setTimeout(() => {
            this.partList = [{
                start: 0,
                end: this.audioDuration
            }]
        }, 500)
    },
    watch: {

    },
    methods: {
        selectActive(val) {
            this.current = val
        },
        // 删除
        delAudio() {
            this.partList.splice(this.current, 1);
            this.current = null
        },
        shearAudio(val) {
            // 获取指针所在数组位置
            let ind = this.partList.findIndex(ele => val >= ele.start && val <= ele.end)
            // 上一条数据
            let prev = {
                start: val,
                end: this.partList[ind].end
            }
            // 分割下一条数据
            let next = {
                start: this.partList[ind].start,
                end: val
            }
            // 改变数组
            this.partList[ind] = prev
            this.partList.splice(ind, 0, next);
            this.current = null
        },
    },
    components: {

    },
};
</script>

<style scoped lang="scss">
.audioTrack-box {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 5px;
    .audioTrack-one {
        width: 100%;
        height: 24px;
        position: relative;
        z-index: 0;
        border: 1px solid #0d1011;
        box-sizing: content-box;
        display: flex;
        align-items: center;
        .audioTrackColor {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 24px;
            background: linear-gradient(180deg, #70cbff 0%, #5c7dff 100%);
            z-index: 1;
        }
        .audioTrackImage {
            width: 100%;
            height: 24px;
            background-image: url(~@/assets/images/editor/audio.png);
            background-size: auto 100%;
            background-repeat: repeat-x;
            position: relative;
            z-index: 2;
        }
    }
    .audioTrack-list {
        width: 100%;
        height: 24px;
        position: relative;
        z-index: 0;
        border: 1px solid #ccc;
        box-sizing: border-box;
        border-radius: 4px;
        overflow: hidden;
        .audioTrackColor {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 24px;
            background: linear-gradient(180deg, #70cbff 0%, #5c7dff 100%);
            z-index: 1;
        }
        .audioTrackImage {
            width: 100%;
            height: 24px;
            background-image: url(~@/assets/images/editor/audio.png);
            background-size: auto 100%;
            background-repeat: repeat-x;
            position: relative;
            z-index: 2;
        }
    }
}
.active {
    border: 1px solid #ff5f5f !important;
}
</style>
