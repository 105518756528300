<template>
  <div class="nav_left_table_wrap">
    <div class="video_table" :class="!navInfo.value == 'template' ? 'video_table_close' : ''">
      <!-- 标题 -->
      <div class="table_title">
        <div class="title">{{ navInfo.label }}</div>
        <i class="el-icon-close" @click="close"></i>
      </div>

      <div class="table_content">
        <!-- tab切换 -->
        <div class="tabs mb20" v-if="mytemplate">
          <div class="item" :class="{ 'active': tabValue1 == item.value }" v-for="(item, idx) in tabs1" :key="idx"
            @click="tabsChange1(item)">
            <div class="txt">{{ item.label }}</div>
            <div class="bar"></div>
          </div>
        </div>
        <!-- 当前模板 -->
        <template v-if="tabValue1 == 1">
          <div class="theme_info">
            <div class="left">
              <img src="" alt="">
            </div>
            <div class="right">
              <div class="title">家庭关系</div>
              <div class="info">
                <div class="label">场景数：</div>
                <div class="value">1</div>
              </div>
              <div class="info">
                <div class="label">时 间：</div>
                <div class="value">2022-11-30</div>
              </div>
              <div class="tips">(提示：数字产品不支持退货)</div>
            </div>
          </div>
          <div class="list_box">
            <div class="title">模特</div>
            <div class="list">
              <img class="item" src="" alt="">
            </div>
          </div>
          <div class="list_box">
            <div class="title">图片</div>
            <div class="list">
              <img class="item" src="" alt="">
            </div>
          </div>
        </template>
        <!-- 更多模板 -->
        <template v-if="tabValue1 == 2">
          <!-- 下方的筛选项目连带搜索 -->
          <div class="search-box">
            <div class="select_box">
              <el-select popper-class="select_popper_dark" v-model="type" placeholder="请选择" size="small"
                @change="typeBtn">
                <el-option v-for="item in type_list" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="search_inp">
              <el-input placeholder="请输入关键字" prefix-icon="el-icon-search" size="small" v-model="name" clearable
                @clear="reset" @keyup.enter.native="reset">
              </el-input>
            </div>

          </div>
          <!-- tab切换 -->
          <div class="tabs mt20">
            <div class="item" :class="{ 'active': tabValue == item.value }" v-for="(item, idx) in tabs" :key="idx"
              @click="tabsChange(item)">
              <div class="txt">{{ item.label }}</div>
              <div class="bar"></div>
            </div>
          </div>
          <!-- 筛选项 -->
          <div class="filter_box mt10">
            <div class="left">
              <div class="item" :class="{ 'active': proportionValue == item.value }" v-for="(item, i) in proportion"
                :key="i" @click="proportionChange(item.value)">
                <img class="icon" :src="item.icon" alt="">
                <div class="txt">{{ item.label }}</div>
              </div>
            </div>
            <div class="right">
              <el-select popper-class="select_popper_dark" v-model="category" placeholder="请选择" size="mini"
                @change="selectBtn">
                <el-option v-for="item in category_list" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- 列表 -->
          <div class="template_list">
            <div class="contentboxs scroll_bar" infinite-scroll-distance="2" v-infinite-scroll="load" v-loading="loading"
              element-loading-background="rgba(0, 0, 0, 0.1)">
              <div class="list">
                <div class="item itemHover" :class="proportionValue == 2 ? 'item1' : 'item2'" v-for="(item, x) in list"
                  :key="x">
                  <div class="item-pic">
                    <div class="picbox">
                      <el-image v-if="item.aspect_ratio == 2" style="width: 106px; height: 190px" :src='item.video_img'
                        fit="contain">
                        <!-- <div slot="error" class="image-slot">
                          <img src="@/assets/images/icon/img.png" alt="">
                        </div> -->
                      </el-image>
                      <el-image v-if="item.aspect_ratio == 1" style="width: 170px; height: 96px" :src='item.video_img'
                        fit="contain">
                        <!-- <div slot="error" class="image-slot">
                          <img src="@/assets/images/icon/img.png" alt="">
                        </div> -->
                      </el-image>
                    </div>
                    <!-- 下方的替换按钮 -->
                    <div class="insert" v-if="type == 2">
                      <div class="button" @click="selectTemp(item)">替换</div>
                    </div>
                    <!-- 下方的购买按钮 -->
                    <div class="insert" v-if="type == 3">
                      <div class="button buy" @click="selectTemp(item)">购买</div>
                    </div>
                  </div>
                  <div class="name">{{ item.name }}</div>
                </div>
              </div>
              <!-- 没有更多 -->
              <Loading :loading="loading" :list="list" :page="page" :totalPage="last_page">
              </Loading>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 提示 -->
    <point-out ref="point" @close='confirmBtn' dialogTitle="提示" dialogWidth='380px' type="works" iconColor="#FF9665"
      borderRadius='10px' :iconShow="true" :cancelShow="true" :affirmShow="true" affirmText='确 定'>
      <div class="zc_dialog_tip_content">
        <div class="text">替换模版不会保留原有数据，请确认是否替换？</div>
      </div>
    </point-out>
  </div>
</template>

<script>
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => { }
    },
  },
  components: {
    PointOut: resolve => require(['@/components/point_out/point_out'], resolve),
    Loading: (resolve) => require(['@/components/loading/index'], resolve)
  },
  data() {
    return {
      mytemplate: true, // 是否已选择模板
      tabs1: [
        {
          label: '当前模板',
          value: '1'
        },
        {
          label: '更多模板',
          value: '2'
        },
      ],
      tabValue1: '1',
      name: '',
      type: 1, // 下拉框选定的值
      type_list: [
        {
          label: '通用',
          value: 1,
        },
        {
          label: 'VIP',
          value: 2,
        },
      ], // 类型列表
      category: '', // 下拉框选定的值
      category_list: [], // 分类列表
      proportion: [
        {
          label: '9:16',
          value: 2,
          icon: ''
        },
        {
          label: '16:9',
          value: 1,
          icon: ''
        },
      ], // 比例
      proportionValue: 1,
      tabs: [
        {
          label: '推荐',
          value: ''
        },
        {
          label: '我的',
          value: '1'
        },
      ],
      tabValue: '',
      loading: false,
      page: 0,
      last_page: 1, // 总页数
      limit: 20,
      list: [],
    }
  },
  created() {
    this.getParams()
    this.getThemeType()
  },
  watch: {

  },
  methods: {
    // 关闭
    close() {
      let obj = {}
      this.$store.commit('videoCreate/SET_LEFT_NAV_INFO', obj)
    },
    // 类型
    typeBtn() {
      this.reset()
    },
    // 分类
    selectBtn() {
      this.reset()
    },
    // 比例
    proportionChange(val) {
      this.proportionValue = val
      this.reset()
    },
    // tab切换-类型
    tabsChange1(item) {
      this.tabValue1 = item.value
    },
    // 比例
    proportionChange(val) {
      this.proportionValue = val
      this.reset()
    },
    // tab切换
    tabsChange(item) {
      this.tabValue = item.value
      this.reset()
    },
    // 加载更多
    load() {
      if (this.loading || this.page === this.last_page || this.last_page === 0) return
      this.page++
      this.getList()
    },
    // 重置
    reset() {
      this.list = []
      this.page = 1
      this.getList()
    },
    // 获取筛选参数
    getParams() {
      this.$digitalApi.templateParams({ from: 1 }).then(res => {
        if (res.code == 1000) {
          this.type_list = res.result.theme_category;
          this.proportion = res.result.aspect_ratio;
          this.type = res.result.theme_category[0].value;
          this.proportionValue = res.result.aspect_ratio[0].value;
        }
      })
    },
    // 获取主题分类
    getThemeType() {
      let obj = {
        type: this.type,
      }
      this.$workbenApi.videoThemeType(obj).then(res => {
        if (res.code == 1000) {
          this.category_list = res.result
          this.category_list.unshift({
            id: '',
            name: '全部'
          })
        }
      })
    },
    // 列表
    getList() {
      this.loading = true
      let obj = {
        page: this.page,
        limit: this.limit,
        type: this.type,
        theme_type: this.category,
        name: this.name,
        aspect_ratio: this.proportionValue,
      }
      if (this.tabValue == 1) {
        this.$workbenApi.videoTemplateMine(obj).then(res => {
          this.loading = false
          if (res.code == 1000) {
            this.list = [...this.list, ...res?.result?.data] || []
            this.last_page = res?.result?.last_page
            this.page = res?.result?.current_page ?? 1
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$workbenApi.videoTemplateIndex(obj).then(res => {
          this.loading = false
          if (res.code == 1000) {
            this.list = [...this.list, ...res?.result?.data] || []
            this.last_page = res?.result?.last_page
            this.page = res?.result?.current_page ?? 1
          } else {
            this.$message.error(res.message)
          }
        })
      }
     
    },
    // 替换
    selectTemp(item) {
      this.$refs.point.openDialogBtn()
    },
    // 确认替换回调
    confirmBtn() { },
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';

.contentbox {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 320px);
  margin-right: -8px;
}

// 更多模板
.search-box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .select_box {
    width: 88px;
    background: #5C6470;
    border-radius: 4px 0px 0px 4px;

    ::v-deep .el-select {
      .el-input__inner {
        border-radius: 4px 0px 0px 4px;
        background: #5C6470;
        border-color: transparent;
        color: #ffffff;
        padding: 0 0 0 6px;
      }

      .el-input__suffix {
        right: 0px;
      }
    }
  }

  .search_inp {
    flex: 1;

    ::v-deep .el-input__inner {
      color: #ffffff;
      background: #272C32;
      border-radius: 0px 4px 4px 0px;
      border-color: transparent;
    }
  }

}

.tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #454A50;

  .item {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .txt {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }

    .bar {
      width: 28px;
      height: 2px;
      background: transparent;
      border-radius: 1px;
      margin-top: 5px;
    }
  }

  .active {
    .txt {
      font-weight: 500;
    }

    .bar {
      background: #FFFFFF;

    }
  }
}

.filter_box {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;

    .item {
      margin-right: 10px;
      width: 46px;
      height: 24px;
      background: #3C4148;
      border-radius: 2px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .txt {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .icon {
        width: 10px;
      }
    }

    .active {
      background: #252F69;
      border: 1px solid #2E4BF2;
    }
  }

  .right {
    width: 78px;
    height: 24px;
    background: #3C4148;
    border-radius: 2px;

    /deep/ .el-select {
      height: 24px;

      .el-input__inner {
        height: 24px;
        border-radius: 4px;
        background: #3C4148;
        border-color: transparent;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }

      .el-input__suffix {
        right: 0px;
        font-size: 12px;

      }

      .el-select__caret {
        font-size: 12px;
      }
    }

    /deep/ .el-input--mini .el-input__icon {
      line-height: 24px;
    }
  }
}

// 当前模板
.theme_info {
  width: 360px;
  height: 160px;
  background: #3C4148;
  border-radius: 2px;
  border: 1px solid #2E4BF2;
  padding: 8px;
  display: flex;
  align-items: center;

  .left {
    width: 177px;
    height: 144px;
  }

  .right {
    margin-left: 12px;

    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      margin-bottom: 16px;
    }

    .info {
      display: flex;
      margin-bottom: 8px;

      .label {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #CDCDCD;
      }

      .value {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .tips {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #CDCDCD;
    }
  }
}

.list_box {
  margin-top: 20px;

  .title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      width: 172px;
      height: 128px;
      border-radius: 4px;
      margin-top: 16px;
      cursor: pointer;
    }
  }
}
</style>