<template>
    <div>
        <el-dialog :title="text" :visible.sync="dialogVisible" width="640px" :close-on-click-modal="false" :modal-append-to-body="false">
            <el-input type="textarea" :rows="4" placeholder="请输入视频分享链接" v-model="textarea">
            </el-input>
            <div class="radio">
                <el-checkbox v-model="checked">提取文本</el-checkbox>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            dialogVisible: false,
            textarea: '',
            checked: false,
        };
    },
    computed: {
        text() {
            if (this.type == 1) {
                return '链接转剧本'
            }
            if (this.type == 2) {
                return '链接转录音'
            }
        },
    },
    created() {

    },
    mounted() {

    },
    watch: {

    },
    methods: {
        open() {
            this.dialogVisible = true
        },
    },
    components: {

    },
};
</script>

<style scoped lang="scss">
.radio {
    position: absolute;
    left: 20px;
    bottom: 30px;
}
</style>
