<template>
    <div>
        <div class="tabs">
            <div class="tabs-item" v-for="item in tab" :key="item.value">
                <div class="item" :class="{active:current==item.value}" @click="current=item.value">{{item.label}}</div>
            </div>
        </div>
        <div class="list voice" v-if="current==1">
            <div class="item" v-for="(item,index) in 7" :key="index">
                <div class="info">
                    <div class="name">
                        未命名03-16 14:22:22
                    </div>
                    <div class="time">
                        2023-03-16 14:22:22
                    </div>
                </div>
                <div class="btn">
                    <img src="@/assets/images/workben/play.png" alt="">
                    <!-- <img src="@/assets/images/workben/stop.png" alt=""> -->
                </div>
            </div>
        </div>
        <div class="select-box" v-if="current==2">
            <el-select size="small" v-model="value" placeholder="请选择">
                <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="list text" v-if="current==2">
            <div class="item" v-for="(item,index) in 7" :key="index">
                <div class="info">
                    <div class="name">
                        居家消毒怎么做？
                        <span v-if="index==1">付费</span>
                    </div>
                    <div class="time">
                        2023-03-16 14:22:22
                    </div>
                </div>
                <div class="btn">
                    <img src="@/assets/images/workben/play.png" alt="">
                    <!-- <img src="@/assets/images/workben/stop.png" alt=""> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            tab: [
                {
                    label: '音频',
                    value: 1,
                },
                {
                    label: '台本',
                    value: 2,
                },
            ],
            current: 1,
            option: [],
            value: '',
        };
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    watch: {

    },
    methods: {

    },
    components: {

    },
};
</script>

<style scoped lang="scss">
.tabs {
    width: 100%;
    border-bottom: 1px solid #5e5e5e;
    display: flex;
    margin-top: 15px;
    height: 30px;
    margin-bottom: 20px;
    .tabs-item {
        width: 50%;
        text-align: center;
        .item {
            font-size: 15px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 30px;
            position: relative;
            cursor: pointer;
        }
        .active::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 28px;
            width: 28px;
            height: 2px;
            background: #ffffff;
            border-radius: 1px;
            transform: translateX(-50%);
        }
    }
}
.select-box {
    width: 200px;
    margin-bottom: 20px;
}
.list {
    width: 100%;

    overflow-y: auto;
    .item {
        width: 350px;
        height: 74px;
        background: #3c4148;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 20px;
        margin-bottom: 20px;
        .info {
            .name {
                font-size: 14px;
                color: #ffffff;
                line-height: 20px;
                span {
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 14px;
                    padding: 1px 7px;
                    background: linear-gradient(
                        322deg,
                        #ff6f00 0%,
                        #ffa52d 100%
                    );
                    border-radius: 2px;
                    margin-left: 12px;
                }
            }
            .time {
                font-size: 12px;
                color: #999999;
                line-height: 17px;
                margin-top: 5px;
            }
        }
        .btn {
            width: 26px;
            height: 26px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.voice {
    height: 360px;
}
.text {
    height: 300px;
}
::v-deep .el-input__inner {
    padding: 0 10px;
    background: #3c4148;
    border-radius: 4px;
    font-size: 14px;
    border: none;
    color: #ffffff;
}
.list::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 8px;
}
.list::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px #636363;
    background: #636363;
}
.list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px #2b3036;
    border-radius: 5px;
    background: #2b3036;
}
</style>
