<template>
    <div>
        <el-dialog title="视频转录音" :visible.sync="dialogVisible" width="640px" :close-on-click-modal="false" :modal-append-to-body="false">
            <el-upload class="upload-box" action="https://jsonplaceholder.typicode.com/posts/" multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">请<em>点击上传</em>MP4视频文件 </div>
            </el-upload>
            <div class="radio">
                <el-checkbox v-model="checked">提取声音</el-checkbox>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {

    },
    data() {
        return {
            dialogVisible: false,
            textarea: '',
            checked: false,
        };
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    watch: {

    },
    methods: {
        open() {
            this.dialogVisible = true
        },
    },
    components: {

    },
};
</script>

<style scoped lang="scss">
.radio {
    position: absolute;
    left: 20px;
    bottom: 30px;
}
.upload-box {
    width: 600px;
    height: 130px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #dcdcdc;
    .el-icon-upload {
        color: #c9c9c9;
        font-size: 50px;
    }
}
</style>
