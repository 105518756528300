<template>
    <div>
        <el-dialog title="提示" :visible.sync="dialogVisible" :width="width" :show-close="(type == 'exit' || type == 'offline') ? false : true" append-to-body :close-on-click-modal="false">
            <div class="title" slot="title">
                <i class="el-icon-warning"></i>
                <div class="name">温馨提示</div>
            </div>
            <div class="text">{{ text }}</div>
            <div class="point" v-if="point">{{ point }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" v-if="cancelShow" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" v-if="secondBtnText" @click="confirmSecondBtn">{{ secondBtnText }}</el-button>
                <el-button size="small" type="primary" @click="confirm">{{ btnText }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: [Number, String],
            default: 1,
        },
        width: { //宽度
            type: String,
            default: '380px',
        },
        cancelShow: { //是否显示取消按钮
            type: Boolean,
            default: false,
        },
        text: { //内容
            type: String,
            default: '',
        },
        point: { //黄字提示
            type: String,
            default: '',
        },
        btnText: { //按钮文字
            type: String,
            default: '确 定',
        },
        secondBtnText: {   //按钮文字
            type: String,
            default: '',
        },
    },
    data() {
        return {
            dialogVisible: false,
        };
    },
    computed: {},
    created() { },
    mounted() { },
    watch: {},
    methods: {
        openDialog() {
            this.dialogVisible = true;
        },
        // 
        confirmSecondBtn() {
            this.dialogVisible = false;
            this.$emit('confirmSecondBtn')
        },
        //
        confirm() {
            if (this.type == 'release' || this.type == 'endLive' || this.type == 'delProduct' || this.type == 'delScene' || this.type == 'liveDel' || this.type == 'oneDel' || this.type == 'twoDel') {
                // 退出/删除
                this.dialogVisible = false
                this.$emit('confirm')
                return
            }
            if (this.type == 'pay') {
                // 续费
                this.dialogVisible = false
                return
            }
            if (this.type == 'exit' || this.type == 'offline') {
                // 退出登录
                this.dialogVisible = false
                if (this.type == 'exit') {
                    this.logoutBtn()
                } else {
                    this.cleanUp()
                }
            }
            if (this.type == 1 || this.type == 2 || this.type == 3 || this.type == 4) {
                // 登录页面的提示
                this.dialogVisible = false
                this.$emit('confirm', this.type)
            }
        },
        // 退出登录
        logoutBtn() {
            var that = this
            that.$publicApi.liveLogout().then(res => {
                if (res.code == 1000) {
                    that.cleanUp()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 清除
        cleanUp() {
            var that = this
            // 清除所有缓存
            localStorage.clear()
            // 清除token
            that.$store.commit('DEL_TOKEN', '')
            // 清除管理员
            that.$store.commit('DEL_ADMIN', '')
            // 清除用户信息
            that.$store.commit('DEL_USER_INFO', '')
            // 清除是否管理员
            that.$store.commit('DEL_ADMIN', '')
            // 清除产品管理试听
            that.$store.commit('DEL_ANCHOR_INFO', '')
            // 跳转登录页面
            that.$router.push('/login')
        },
    },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
    border-radius: 10px;
}

::v-deep .el-dialog__body {
    padding: 0 20px 10px 50px;
}

.title {
    display: flex;
    align-items: center;

    .el-icon-warning {
        font-size: 24px;
        color: #ff7432;
    }

    .name {
        padding-left: 10px;
        font-size: 16px;
        margin-top: -3px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
    }
}

.text {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding-top: 8px;
}

.point {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ff7432;
    line-height: 23px;
    padding-top: 8px;
}
</style>
